<template>
  <div class="pay-resoult-page">
    <div>
      <div class="resoult">
        <img :src="require(`@/assets/${payState.iconUrl}`)" alt="" />
      </div>
      <div class="desc">{{ payState.resoult }}</div>
      <van-button @click="goMain" type="primary">返回首页</van-button>
      <div style="width: 100%">
        <dl class="dlStyle" @click="handle">
          <dt>
            <img src="@/assets/bw.jpg" alt="" width="100%" height="auto" />
          </dt>
          <dd>
            <p>平安E家平安.百万医疗险</p>
            <p>1万元免赔额，特药险种覆盖全</p>
            <p>
              <span style="font-weight: bold; font-size: 0.4rem; color: red"
                >￥214元起</span
              >
            </p>
            <p style="color: #666">由平安财险提供服务</p>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { components } from "./options";
import { sendOrder } from "@/api";

const orderModule = namespace("order");
const orderGetter = orderModule.Getter;
const orderState = orderModule.State;

@Component({
  components,
})
class PayResult extends Vue {
  @orderState("orderInfo") orderInfo;
  @orderGetter("orderAmount") orderAmount;
  success = true;
  payState = {
    resoult: "领取成功",
    iconUrl: "success.png",
  };
  goMain() {
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("idCard");
    sessionStorage.removeItem("phone");
    sessionStorage.removeItem("carCode");
    this.$router.go(-1); //返回上一层
  }
  handle() {
    window.location.href =
      "https://emcs.pa18.com/product/p_eLifeGeneral/index.html?appType=01&key=12205190000000911386";
  }
}
export default PayResult;
</script>

<style lang="scss" src="./index.scss" scoped></style>
